import { gql } from '@apollo/client';

export const StreamBasicFields = gql`
  fragment StreamBasicFields on Stream {
    id
    slug
    description
    name
    scheduleDate
    timeZone {
      tzCode
      offset
    }
    repeatsEveryEnum
    isPurchased
    streamStatus
    imageURL
  }
`;

export const StreamFields = gql`
  fragment StreamFields on Stream {
    id
    slug
    description
    name
    scheduleDate
    deletedAt
    hashtags {
      id
      name
    }
    timeZone {
      tzCode
      offset
    }
    sponsors {
      id
      logo
      logoUrl
      name
    }
    moderator {
      id
    }
    mentions {
      id
      mentionType
      store {
        slug
        storeDetails {
          storeName
        }
      }
      targetType
      unregisteredContact {
        id
        name
        representativeName
      }
    }
    isPurchased
    parentStreamId
    repeatingTitle
    repeatsEvery {
      days
      hours
      milliseconds
      minutes
      months
      seconds
      years
    }
    repeatsEveryEnum
    isPPV
    streamStatus
    imageURL
    requestedPrice
    isFree
    store {
      id
      slug
    }
  }
`;

export const GetUpcomingStreamsEntities = gql`
  fragment GetUpcomingStreamsEntities on Stream {
    id
    slug
    name
    imageURL
    price
    description
    isFree
    streamStatus
    repeatingTitle
    repeatsEveryEnum
    scheduleDate
    timeZone {
      tzCode
      offset
    }
    isPurchased
    store {
      id
      slug
      lastName
      firstName
      storeDetails {
        id
        storeName
        avatarURL
      }
    }
  }
`;

export const StreamNoteFields = gql`
  fragment StreamNoteFields on StreamNote {
    id
    note
    relativeTs
  }
`;
